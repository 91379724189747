import React from "react"
import PropTypes from "prop-types"
import firebase from "gatsby-plugin-firebase"
import { useDocumentData } from "react-firebase-hooks/firestore"
import Img from "gatsby-image"

import "./hero.scss"
import { graphql, StaticQuery } from "gatsby"

const Header = ({ lang = "it" }) => {
  const [users] = useDocumentData(
    !!firebase.firestore
      ? firebase
          .firestore()
          .collection("statistics")
          .doc("users")
      : null
  )

  return (
    <StaticQuery
      query={graphql`
        query HeroImagesQuery {
          appStoreButtonIT: file(
            relativePath: { eq: "app-store-badge-it.svg" }
          ) {
            publicURL
          }

          appStoreButtonEN: file(
            relativePath: { eq: "app-store-badge-en.svg" }
          ) {
            publicURL
          }

          playButtonIT: file(relativePath: { eq: "google-play-badge-it.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          playButtonEN: file(relativePath: { eq: "google-play-badge-en.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          logo: file(relativePath: { eq: "logo_text.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          phone: file(relativePath: { eq: "phone.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const {
          logo,
          phone,
          playButtonEN,
          playButtonIT,
          appStoreButtonEN,
          appStoreButtonIT,
        } = data

        return (
          <>
            <div className="hero">
              <img
                className="hero__logo"
                src={logo}
                alt="Ogado logo text"
              ></img>

              <div className="hero__phone">
                <Img
                  fluid={phone.childImageSharp.fluid}
                  alt="Phone running Ogado"
                  className="hero__phone-img"
                />
              </div>
              <div className="hero__main">
                <div className="hero__main-logo">
                  <Img
                    fluid={logo.childImageSharp.fluid}
                    alt="Ogado logo text"
                  />
                </div>
                <p className="hero__main-tagline">
                  <strong>
                    {lang === "it"
                      ? `La community dell'intrattenimento`
                      : `Entertainment done together`}
                  </strong>
                </p>
                {lang === "it" ? (
                  <>
                    <p className="hero__main-description">
                      L’app che ti permette di avere <br />
                      la tua lista di <br />
                      <strong>Film</strong>, <strong>Serie TV</strong>,{" "}
                      <strong>Libri</strong> e <strong>Musica</strong> <br />
                      sempre in tasca.
                    </p>
                    <p className="hero__main-description">
                      <strong>{(users as any)?.totalUsers}</strong>{" "}
                      <strong>
                        persone fanno già parte della nostra community, cosa
                        aspetti ad unirti anche tu?
                      </strong>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="hero__main-description">
                      The app that lets you have <br />
                      your list of <br />
                      <strong>Movies</strong>, <strong>TV Series</strong>,{" "}
                      <strong>Books</strong> and <strong>Music</strong> <br />
                      always in your pocket.
                    </p>
                    <p className="hero__main-description">
                      <strong>{(users as any)?.totalUsers}</strong>{" "}
                      <strong>
                        people have already joined our community what are you
                        waiting to join too?
                      </strong>
                    </p>
                  </>
                )}
                <p className="hero__main-download">
                  <strong>
                    {lang === "it"
                      ? `Installa l'app per far parte della community`
                      : `Get the app and join our community`}
                  </strong>
                  <div className="hero__main-download-buttons">
                    <a
                      href="https://ogado.app/download"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hero__main-download-image"
                    >
                      <Img
                        style={{ maxWidth: `150px` }}
                        fluid={
                          lang === "it"
                            ? playButtonIT.childImageSharp.fluid
                            : playButtonEN.childImageSharp.fluid
                        }
                        alt="Disponibile su Google Play"
                      />
                    </a>

                    <a
                      href="https://ogado.app/ios"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hero__main-download-image"
                    >
                      <img
                        style={{ maxWidth: `150px`, minHeight: `48px` }}
                        src={
                          lang === "it"
                            ? appStoreButtonIT.publicURL
                            : appStoreButtonEN.publicURL
                        }
                        alt="Disponibile su Google Play"
                      ></img>
                    </a>
                  </div>
                </p>
              </div>
            </div>

            <div className="hero__search">
              <input
                type="text"
                className="st-default-search-input"
                placeholder={lang === "it" ? "Cerca" : "Search"}
              />
            </div>
          </>
        )
      }}
    />
  )
}

Header.propTypes = {
  lang: PropTypes.string,
}

Header.defaultProps = {
  lang: `it`,
}

export default Header
