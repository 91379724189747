import {
  Button,
  FormControl,
  FormHelperText,
  TextField,
} from "@material-ui/core"
import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

import "./newsletter-box.scss"

export const NewsletterBox = ({ lang = "it" }) => {
  const isIT = lang === "it"
  const [email, setEmail] = useState("")
  const [mailchimpData, setMailchimpData] = useState({})
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [emailText, setEmailText] = useState("")
  const [errorText, setErrorText] = useState(``)

  const handleClick = event => {
    event.preventDefault()
    addToMailchimp(
      email,
      {},
      "https://app.us2.list-manage.com/subscribe/post?u=548bbd16695957fa0f56c4dd8&amp;id=686e470c79"
    ).then(data => {
      setEmail("")
      setMailchimpData(data)

      if (data.result === `success`) {
        setEmailText(
          isIT ? `Registrato correttamente!` : `Signed in succefully!`
        )
        setErrorText(``)
        setInvalidEmail(false)
      } else {
        setInvalidEmail(true)
        setErrorText(
          isIT
            ? `C'è stato un errore nella registrazione, riprova`
            : `Some error occurred whilst signing you up, please retry`
        )
      }
    })
  }

  const checkMail = email => {
    const regexp = /\S+@\S+\.\S+/
    const test = regexp.test(email)
    setInvalidEmail(!test)
    setEmail(email)

    setErrorText(test ? `` : isIT ? `Email non valida` : `Invalid email`)

    if (mailchimpData) {
      setEmailText(``)
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query NewsletterBoxImagesQuery {
          newsletterBox: file(relativePath: { eq: "newsletter-box.svg" }) {
            publicURL
          }
        }
      `}
      render={data => {
        const { newsletterBox } = data

        return (
          <div
            className="newsletter-box"
            style={{
              backgroundImage: `url(${newsletterBox.publicURL})`,
            }}
          >
            {lang === "it" ? (
              <>
                <h3 className="newsletter-box-title">
                  Vuoi restare sempre aggiornato sulle ultime novità di{" "}
                  <strong>Ogado</strong>?
                </h3>
                <p className="newsletter-box-subtitle">
                  Iscriviti alla nostra newsletter per ricevere tutte le
                  informazioni, gli aggiornamenti e le novità!{" "}
                </p>
              </>
            ) : (
              <>
                <h3 className="newsletter-box-title">
                  Would you like to always be updated with the latest{" "}
                  <strong>Ogado</strong> news?
                </h3>
                <p className="newsletter-box-subtitle">
                  Subscribe to our newsletter to never miss a thing, we'll send
                  you all the infos, updates and news!
                </p>
              </>
            )}
            <form
              className="newsletter-box-form"
              method="GET"
              target="_top"
              action="#"
            >
              <FormControl error={invalidEmail}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  margin="normal"
                  variant="outlined"
                  helperText={emailText}
                  value={email}
                  onChange={event => checkMail(event.target.value)}
                  data-cy="email-input"
                  InputLabelProps={{
                    style: { color: `white` },
                  }}
                  InputProps={{
                    style: {
                      color: `white`,
                      backgroundColor: `rgba(255, 255, 255, 0.2)`,
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      color: `white`,
                    },
                  }}
                  style={{
                    color: `white`,
                    borderColor: `white`,
                    borderRadius: `6px`,
                  }}
                />
                <FormHelperText data-cy="error-text" hidden={!invalidEmail}>
                  {errorText}
                </FormHelperText>
              </FormControl>
              <Button
                onClick={handleClick}
                variant="outlined"
                color="primary"
                disabled={email === "" || invalidEmail}
                style={{
                  height: `56px`,
                  backgroundColor: `white`,
                  color: `var(--ogado-blue)`,
                }}
                data-cy="email-button"
                type="submit"
              >
                {isIT ? `Aggiungimi!` : `Add me!`}
              </Button>
            </form>
          </div>
        )
      }}
    />
  )
}
