import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import firebase from "gatsby-plugin-firebase"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"

import "./content.scss"
import { NewsletterBox } from "../newsletter-box/newsletter-box"

export default ({ lang = "it" }) => {
  const canLoad = !!firebase.firestore

  const [series, isLoading] = useDocumentDataOnce(
    canLoad
      ? firebase
          .firestore()
          .collection("series")
          .doc(lang)
          .collection("series")
          .doc("76479")
      : null
  )

  return (
    <StaticQuery
      query={graphql`
        query ContentImagesQuery {
          appStoreButtonIT: file(
            relativePath: { eq: "app-store-badge-it.svg" }
          ) {
            publicURL
          }

          appStoreButtonEN: file(
            relativePath: { eq: "app-store-badge-en.svg" }
          ) {
            publicURL
          }

          playButtonIT: file(relativePath: { eq: "google-play-badge-it.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          playButtonEN: file(relativePath: { eq: "google-play-badge-en.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const {
          appStoreButtonIT,
          appStoreButtonEN,
          playButtonIT,
          playButtonEN,
        } = data

        return (
          <div className="content">
            {lang === "it" ? (
              <p className="content__title margin">
                Una <strong>libreria digitale</strong> sempre{" "}
                <strong>aggiornata</strong> sarà a tua disposizione e ti
                permetterà in pochi secondi di trovare quella{" "}
                <strong>serie di cui tutti parlano</strong>!
              </p>
            ) : (
              <p className="content__title margin">
                A <strong>digital library</strong> always{" "}
                <strong>updated</strong> will be available for you and will let
                you find in just a matter of seconds the new{" "}
                <strong>tv series that everyone is talking about</strong>!
              </p>
            )}
            <div className="content__wrapper">
              <div
                className="row content__search-row"
                style={{ justifyContent: `space-evenly` }}
              >
                <div className="row content__search">
                  <span className="material-icons">search</span>
                  <span>
                    {lang === "it" ? `Cerca un titolo` : `Search content`}
                  </span>
                </div>
                <div className="row content__wrapper-input">
                  <div className="content__input">
                    <div className="content__input-input with-icon">
                      <input
                        type="text"
                        readOnly={true}
                        value="The Boys"
                      ></input>
                      <span className="material-icons">search</span>
                    </div>
                  </div>
                </div>
              </div>
              {lang === "it" ? (
                <p className="content__title">
                  Avrai le <strong>informazioni</strong> inerenti alla tua
                  ricerca, come attori, trama e molto altro…
                </p>
              ) : (
                <p className="content__title">
                  You will have all the <strong>informations</strong> about what
                  you just searched, like actors, air date, overview and much
                  more...
                </p>
              )}
              {canLoad && !isLoading ? (
                <div className="content__content">
                  <h3 className="content__content-title">
                    {(series as any).name}
                  </h3>
                  <p className="content__content-subtitle">
                    {lang === "it" ? "Aggiunto" : "Added"}{" "}
                    {(series as any).timesAdded}{" "}
                    {(series as any).timesAdded === 1
                      ? lang === "it"
                        ? "volta"
                        : "time"
                      : lang === "it"
                      ? "volte"
                      : "times"}
                  </p>
                  <div className="row content__content-gallery">
                    <div
                      style={{
                        backgroundImage: `url('https://image.tmdb.org/t/p/w500${
                          (series as any).poster_path
                        }')`,
                      }}
                      className="content__content-image"
                    >
                      <div className="content__content-image-description">
                        <span className="genre">
                          <strong>{(series as any).genres[0].name}</strong>
                        </span>
                        <span className="year">
                          {(series as any).first_air_date.substring(0, 4)}
                        </span>
                      </div>
                    </div>
                    {(series as any).images?.posters?.map((image, index) => (
                      <img
                        key={image.file_path}
                        src={`https://image.tmdb.org/t/p/w500${image.file_path}`}
                        className="content__content-image"
                        alt={`${(series as any).name} immagine ${index}`}
                      ></img>
                    ))}
                  </div>
                  <p className="content__content-overview">
                    {(series as any).overview}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              {lang === "it" ? (
                <p className="content__title">
                  Saprai sempre su{" "}
                  <strong>quali piattaforme è disponibile</strong>.
                </p>
              ) : (
                <p className="content__title">
                  You will always know on{" "}
                  <strong>which platforms the content is available</strong>
                  Saprai sempre su{" "}
                  <strong>quali piattaforme è disponibile</strong>.
                </p>
              )}

              <div className="content__platforms">
                <span>
                  <strong>
                    {lang === "it" ? "Disponibile su" : "Available on"}
                  </strong>
                </span>

                <div className="content__platforms-list">
                  {(series as any)?.platforms.map(platform => (
                    <a
                      href={platform.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="content__platforms-platform"
                    >
                      <div
                        style={{ backgroundImage: `url(${platform.logo.url})` }}
                      ></div>
                    </a>
                  ))}
                  <a
                    href="https://www.primevideo.com/detail/0LTURNRIIF1SFJUX32I7XALS8U/ref=atv_hm_hom_c_Q0o7DL_2_4?tag=ogado-21"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="content__platforms-platform dark"
                  >
                    <div></div>
                  </a>
                </div>
              </div>

              {lang === "it" ? (
                <p className="content__title">
                  Una volta trovato ciò che stavi cercando puoi salvarlo nelle{" "}
                  <strong>tue liste</strong>.
                </p>
              ) : (
                <p className="content__title">
                  When you have found what you were looking for, you can save it
                  on
                  <strong>your lists</strong>
                </p>
              )}

              <div
                className="row content__add-row"
                style={{ justifyContent: `space-evenly` }}
              >
                <div className="row content__add">
                  <span className="material-icons">add</span>
                  <span>
                    {lang === "it"
                      ? "Aggiungilo alla tua lista"
                      : "Add it to your list"}
                  </span>
                </div>
                <div className="row content__wrapper-button">
                  <div className="content__button">
                    <button className="row content__button-button">
                      <span className="material-icons">add</span>
                      <strong>
                        {lang === "it"
                          ? "Aggiungi alla tua lista"
                          : "Add it to your list"}
                      </strong>
                    </button>
                  </div>
                </div>
              </div>

              <div className="content__lists">
                <div className="content__lists-list">
                  {[
                    { class: "movies", icon: "movies" },
                    { class: "series", icon: "tv" },
                    { class: "music", icon: "library_music" },
                    { class: "books", icon: "book" },
                    { class: "games", icon: "gamepad" },
                  ].map(type => (
                    <div
                      className={`content__lists-element ${type.class}`}
                      key={type.class}
                    >
                      <span className="material-icons">{type.icon}</span>
                    </div>
                  ))}
                </div>
              </div>

              <p
                className="content__title"
                style={{ marginTop: `4rem`, marginBottom: `1rem` }}
              >
                <strong>
                  {lang === "it"
                    ? "Scarica ora l'app da Google Play"
                    : "Get the app on Google Play"}
                </strong>
              </p>

              <a
                href="https://ogado.app/download"
                target="_blank"
                rel="noopener noreferrer"
                style={{ minWidth: "175px", minHeight: `59px` }}
              >
                <Img
                  fluid={
                    lang === "it"
                      ? playButtonIT.childImageSharp.fluid
                      : playButtonEN.childImageSharp.fluid
                  }
                  alt="Disponibile su Google Play"
                />
              </a>

              <p
                className="content__title"
                style={{ marginTop: `1rem`, marginBottom: `1rem` }}
              >
                <strong>
                  {lang === "it"
                    ? "Scarica ora l'app dall'App Store"
                    : "Get the app on the App Store"}
                </strong>
              </p>

              <a
                href="https://ogado.app/ios"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ maxWidth: `175px`, minHeight: `59px` }}
                  src={
                    lang === "it"
                      ? appStoreButtonIT.publicURL
                      : appStoreButtonEN.publicURL
                  }
                  alt="Disponibile su Google Play"
                />
              </a>

              <NewsletterBox lang={lang} />
            </div>
          </div>
        )
      }}
    />
  )
}
